<template>
  <el-container class="login-container">
    <div style="text-align: center; max-width: 700px; margin-bottom: 50px">
      <h1 class="main-header">Forgot your password?</h1>
      <div>Enter your email address associated with your account and we'll send you a link to reset your password.</div>
      <el-main>
        <el-form
          class="custom-form"
          label-position="top"
          label-width="100px"
          :rules="rules"
          :model="forgotForm"
          ref="form"
          @submit.prevent
          @keyup.enter="onSubmit"
        >
          <TextInput v-model="forgotForm.emailAddress" formProps="emailAddress" formLabel="Email" />
          <el-form-item>
            <el-button class="custom-btn primary-btn gray" @click="onSubmit" :loading="isLoading" :disabled="isLoading">Submit</el-button>
            <div class="center-text fs-12">
              <span class="sub-label-light fs-16">
                Not yet a member?
                <router-link :to="`/signup`">Sign up</router-link> now!
              </span>
            </div>
          </el-form-item>
        </el-form>
      </el-main>
    </div>
  </el-container>
</template>

<script>
import { reactive, ref } from 'vue';
import TextInput from '@/components/Share/TextInput.vue';
import { ElMessage } from 'element-plus';
import authService from '@/services/auth-service';

export default {
  components: {
    TextInput,
  },
  setup() {
    const form = ref(null);
    const forgotForm = reactive({
      emailAddress: '',
    });
    const isLoading = ref(false);

    const rules = {
      emailAddress: [
        {
          required: true,
          message: 'Please enter email address',
        },
        {
          type: 'email',
          message: 'Please enter correct email address',
        },
      ],
    };

    const onSubmit = () => {
      form.value.validate(async (valid) => {
        if (valid) {
          isLoading.value = true;
          const isSuccess = await authService.requestForgotPassword({
            ...form.value.model,
          });

          if (isSuccess) {
            ElMessage.success({
              showClose: true,
              message: 'Reset link will send to your email address.',
            });
          } else {
            ElMessage.error({
              showClose: true,
              message: 'Account not found',
            });
          }

          isLoading.value = false;
        }
      });
    };

    return {
      form,
      forgotForm,
      rules,
      onSubmit,
      isLoading,
    };
  },
};
</script>
