<template>
  <el-main>
    <el-row style="margin-bottom: 50px; text-align: center">
      <el-col :span="24">
        <div>
          <h1 class="main-header">Reset your password</h1>
        </div>
        <div class="outer-div">
          <div style="max-width: 700px; padding: 20px 0" class="inner-div">
            <el-form
              label-position="top"
              label-width="200px"
              :model="resetPassword.formModel"
              :rules="resetPassword.formRule"
              ref="resetPasswordForm"
            >
              <el-form-item>
                <el-row :gutter="20">
                  <el-col>
                    <div>
                      <TextInput v-model="resetPassword.formModel.password" formProps="password" formLabel="Password" inputType="password" />
                    </div>
                  </el-col>
                  <el-col>
                    <div>
                      <TextInput
                        v-model="resetPassword.formModel.confirmPassword"
                        formProps="confirmPassword"
                        formLabel="Confirm Password"
                        inputType="password"
                      />
                    </div>
                  </el-col>
                </el-row>
              </el-form-item>

              <el-form-item>
                <el-button class="custom-btn primary-btn gray" @click="onSubmit" :disabled="isLoading" :loading="isLoading">Reset</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
import { onMounted, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import authServices from '@/services/auth-service';
import { removeAuthInfo, saveAuthInfo } from '@/helpers/auth-helper';
import TextInput from '@/components/Share/TextInput.vue';
import { ElMessage } from 'element-plus';

export default {
  components: {
    TextInput,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const resetPasswordForm = ref(null);
    const isLoading = ref(false);
    const resetPassword = reactive({
      detail: {
      },
      formModel: {
        password: '',
        confirmPassword: '',
      },
      formRule: {
        password: [
          {
            required: true,
            message: 'Please enter password',
          },
          {
            min: 6,
            message: 'Password must be at least 6 character',
          },
        ],
        confirmPassword: [
          {
            required: true,
            message: 'Please enter password',
          },
          {
            min: 6,
            message: 'Password must be at least 6 character',
          },
          {
            validator: (rule, value, callback) => {
              if (value !== resetPassword.formModel.password) {
                callback(new Error("Password doesn't match"));
              } else {
                callback();
              }
            },
          },
        ],
      },
    });

    onMounted(async () => {
      await authServices.checkResetPasswordValidity(route.params.id).then(
        (data) => {
          resetPassword.detail = {
            ...data,
          };
          console.log(resetPassword);
        },
        () => {
          router.go(-1);
        }
      );
    });

    const onSubmit = () => {
      resetPasswordForm.value.validate((valid) => {
        if (valid) {
          isLoading.value = true;
          saveAuthInfo(resetPassword.detail);
          authServices
            .updateUserPassword(resetPassword.detail.userId, {
              newSecret: resetPasswordForm.value.model.password,
            })
            .then(
              () => {
                router.push('/login');
                ElMessage.success({
                  showClose: true,
                  message: 'Reset password successfully',
                });
              },
              () => {
                ElMessage.error({
                  showClose: true,
                  message: 'Unable to reset password',
                });
              }
            )
            .finally(() => {
              removeAuthInfo();
              isLoading.value = false;
            });
        }
      });
    };

    return {
      resetPassword,
      isLoading,
      resetPasswordForm,
      onSubmit,
    };
  },
};
</script>
