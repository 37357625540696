<template>
  <el-container class="login-container">
    <div style="text-align:center; max-width: 700px; margin-bottom: 50px;">
      <h1 class="main-header">Welcome back to XMarketplace!</h1>
      <div>At XMarketplace, you can buy and sell exclusive collectibles easily and securely, without worry about fakes or scams.</div>
      <el-main>
        <el-form
          class="custom-form"
          label-position="top"
          label-width="100px"
          :model="loginForm"
          :rules="rules"
          ref="loginForm"
          @keyup.enter="onSubmit"
        >
          <TextInput v-model="loginForm.username" formProps="username" formLabel="Username" />
          <div style="margin-top: 35px;">
            <TextInput
              v-model="loginForm.secret"
              inputType="password"
              formProps="secret"
              formLabel="Password"
            />
          </div>
          <div class="right-text fs-12" style="margin-top: -10px; margin-bottom: 20px;">
            <router-link to="/forgotpassword"><span class="sub-label-light fs-16">Forgot password?</span></router-link>
          </div>
          <el-form-item>
            <el-button
              class="custom-btn primary-btn gray"
              @click="onSubmit"
              :loading="isSigningIn"
            >Login</el-button>
          </el-form-item>
          <div class="center-text fs-12">
            <span class="sub-label-light fs-16">Not yet a member? <router-link :to="`/signup`">Sign up</router-link> now!</span>
          </div>
        </el-form>
      </el-main>
    </div>
  </el-container>
</template>

<script>
import { SIGN_IN } from '@/store/modules/auth/actions-type';
import { mapActions, mapState } from 'vuex';
import TextInput from '@/components/Share/TextInput.vue';
import { validateUserName } from '@/helpers';

export default {
  name: 'Login',
  data() {
    return {
      loginForm: {
        username: '',
        secret: '',
      },
      rules: {
        username: [
          {
            required: true,
            message: 'Please enter your username',
          },
          {
            validator: validateUserName,
          },
        ],
        secret: [
          {
            required: true,
            message: 'Please enter your password',
          },
          {
            min: 6,
            message: 'Password must be at least 6 character',
          },
        ],
      },
    };
  },
  computed: {
    ...mapState('auth', ['isSigningIn']),
  },
  methods: {
    ...mapActions('auth', [SIGN_IN]),
    onSubmit() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          const loginDetails = {
            ...this.loginForm,
            applicationDomain: process.env.VUE_APP_APPLICATION_DOMAIN,
          };

          this.SIGN_IN(loginDetails);
        }
      });
    },
  },
  components: {
    TextInput,
  },
};
</script>
