<template>
  <el-main>
    <el-row style="margin-bottom: 50px; text-align: center;">
      <el-col :span="24">
        <div>
          <h1 class="main-header">Create An Account</h1>
          <div>Sign up to start buying and selling collectibles securely.</div>
        </div>
        <div class="outer-div">
          <div style="max-width: 700px; padding: 20px 0;" class="inner-div">
            <el-form
              label-position="top"
              label-width="200px"
              :model="registrationForm"
              :rules="rules"
              ref="registrationForm"
              @keyup.enter="onSubmit"
            >
              <el-form-item>
                <el-row :gutter="20">
                  <el-col :span="12" :xs="24">
                    <div>
                      <TextInput
                        v-model="registrationForm.firstName"
                        formProps="firstName"
                        formLabel="First Name"
                      />
                    </div>
                  </el-col>
                  <el-col :span="12" :xs="24">
                    <div>
                      <TextInput
                        v-model="registrationForm.lastName"
                        formProps="lastName"
                        formLabel="Last Name"
                      />
                    </div>
                  </el-col>
                  <el-col :span="12" :xs="24">
                    <div>
                      <TextInput
                        v-model="registrationForm.userName"
                        formProps="userName"
                        formLabel="Username"
                      />
                    </div>
                  </el-col>
                  <el-col :span="12" :xs="24">
                    <div>
                      <TextInput
                        v-model="registrationForm.emailAddress"
                        formProps="emailAddress"
                        formLabel="Email"
                      />
                    </div>
                  </el-col>
                  <el-col :span="12" :xs="24">
                    <div>
                      <TextInput
                        v-model="registrationForm.password"
                        formProps="password"
                        formLabel="Password"
                        inputType="password"
                      />
                    </div>
                  </el-col>
                  <el-col :span="12" :xs="24">
                    <div>
                      <TextInput
                        v-model="registrationForm.confirm_password"
                        formProps="confirm_password"
                        formLabel="Confirm Password"
                        inputType="password"
                      />
                    </div>
                  </el-col>
                </el-row>
              </el-form-item>

              <el-form-item>
                <el-button
                  class="custom-btn primary-btn gray"
                  @click="onSubmit"
                  :loading="isSigningUp"
                >Sign Up</el-button>
              </el-form-item>

              <div class="center-text fs-12">
                <span class="sub-label-light fs-16">
                  Already have an account?
                  <router-link :to="`/login`">Log In</router-link>
                </span>
              </div>
            </el-form>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
import { SIGN_UP } from '@/store/modules/auth/actions-type';
import { mapActions, mapState } from 'vuex';
import TextInput from '@/components/Share/TextInput.vue';
import { validateUserName } from '@/helpers';

export default {
  name: 'Sign Up',
  data() {
    return {
      registrationForm: {
        firstName: '',
        lastName: '',
        userName: '',
        emailAddress: '',
        password: '',
        confirm_password: '',
      },
      rules: {
        firstName: [
          {
            required: true,
            message: 'Please enter first name',
          },
        ],
        lastName: [
          {
            required: true,
            message: 'Please enter last name',
          },
        ],
        userName: [
          {
            required: true,
            message: 'Please enter user name',
          },
          {
            validator: validateUserName,
          },
        ],
        password: [
          {
            required: true,
            message: 'Please enter password',
          },
          {
            min: 6,
            message: 'Password must be at least 6 character',
          },
        ],
        confirm_password: [
          {
            required: true,
            message: 'Please enter password',
          },
          {
            min: 6,
            message: 'Password must be at least 6 character',
          },
          {
            validator: (rule, value, callback) => {
              if (value !== this.registrationForm.password) {
                callback(new Error("Password doesn't match"));
              } else {
                callback();
              }
            },
          },
        ],
        emailAddress: [
          {
            required: true,
            message: 'Please enter email address',
          },
          {
            type: 'email',
            message: 'Please enter correct email address',
          },
        ],
      },
    };
  },
  computed: {
    ...mapState('auth', ['isSigningUp']),
  },
  methods: {
    ...mapActions('auth', [SIGN_UP]),
    onSubmit() {
      this.$refs.registrationForm.validate((valid) => {
        if (valid) {
          const userDetails = {
            ...this.registrationForm,
            secret: this.registrationForm.password,
            displayName: `${this.registrationForm.firstName} ${this.registrationForm.lastName}`,
            applicationDomain: process.env.VUE_APP_APPLICATION_DOMAIN,
          };
          this.SIGN_UP(userDetails);
        }
      });
    },
  },
  components: {
    TextInput,
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.register-container {
  align-items: center;
  justify-content: center;
  background-color: $--color-primary-bg;
}

.custom-form {
  width: 100%;
}
</style>
